import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Layout from '../components/Layout'
import Staff from '../containers/Staff'
import { H1 } from '../components/Titles'
export default () => (
  <Layout>
    <H1>Contact</H1>
    <div css={css`
    display:grid; grid-template-columns: repeat(auto-fill, minmax(600px, 1fr) ) ;
 `}>
      <Staff />
    </div>
  </Layout>
)
